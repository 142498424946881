











































































import AttendanceDetailsSideNav from "@/components/attandanceComponent/attendanceDetailsSideNav/attendanceDetailsSideNav";
import "@/components/attandanceComponent/attendanceDetailsSideNav/attendanceDetailsSideNav.less";
export default AttendanceDetailsSideNav;
